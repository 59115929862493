export default {
  APP_ENV: process.env.NODE_ENV,
  
  /**
   * AZCLOUD_BOOK
   *
   * @type {String} - Indica el libro de contenidos digitales a renderizar para la web de soporte
   */
  AZCLOUD_BOOK: process.env.VUE_APP_AZSUPPORT_AZCLOUD_BOOK,

  /**
   * IS_MOBILE
   *
   * @type {Boolean} - Indica si la aplicación se ha compilado para móviles
   */
  IS_MOBILE: process.env.VUE_APP_MOBILE,
  PLATFORM: {
    IOS: "ios",
    ANDROID: "android",
    WEB: undefined,
  },

  /**
   * IS_PRODUCTION
   *
   * @constant {Boolean} - Indica si la aplicación se ha compilado en modo producción o desarrollo
   */
  IS_PRODUCTION: process.env.NODE_ENV === "production",

  /**
   * SETTINGS
   *
   * @constant {Object} SETTINGS - Ajustes de la aplicación
   * @property {Number} ARCADE_MODE_TIME - Tiempo de espera en milisegundos para evitar enviar muchos eventos seguidos
   * @property {Boolean} AUTH - Indica si la aplicación tiene autenticación
   * @property {String} DEFAULT_MODE - Indica el entorno seleccionado cuando se inicia la aplicación en desarrollo: 'DEV', 'PRE', 'PROD'
   * @property {String} DEFAULT_ROUTE - Nombre de la ruta por defecto
   * @property {Boolean} ON_BOARD - Indica si existe una presentación al iniciar la app por primera vez
   */
  SETTINGS: {
    HAS_ON_BOARD: process.env.VUE_APP_HAS_ON_BOARD,
    HAS_GOOGLEMAP: process.env.VUE_APP_HAS_GOOGLEMAP,
    HAS_AUTHENTICATION: process.env.VUE_APP_HAS_AUTHENTICATION,
    ARCADE_MODE_TIME: process.env.VUE_APP_ARCADE_MODE_TIME,
    DEFAULT_MODE: process.env.NODE_ENV,
    DEFAULT_ROUTE: process.env.VUE_APP_DEFAULT_ROUTE,
    DEBOUNCE_WAIT_TIME: 900,
  },

  /**
   * @typedef {Object} CONNECT_MODE
   * @property {String} AZCLOUD_API_URL - URL del api de AirzoneCloud
   * @property {String} AZCONTROL_API_URL - URL del api de AirzoneControl
   * @property {String} AZCONTROL_API_KEY - Api key de AirzoneControl
   */

  /**
   * @typedef {Object} CONNECT_GOOGLEMAP
   * @property {String} IOS_KEY - Key para dispostivos iOS
   * @property {String} ANDROID_KEY - Key para dispositivos Android
   */

  /**
   * @typedef {Object} CONNECT_ALGOLIA
   * @property {String} CLIENT_ID - ID de cliente para el buscador de Algolia
   * @property {String} API_KEY - Key para el buscador de Algolia
   */

  /**
   * CONNECT
   *
   * @constant {Object} CONNECT - Parámetros de conexión con el backend
   * @property {CONNECT_MODE} DEV - Parámetros de conexión en entorno DEV
   * @property {CONNECT_MODE} PRE - Parámetros de conexión en entorno PRE
   * @property {CONNECT_MODE} PROD - Parámetros de conexión en entorno PROD
   * @property {CONNECT_GOOGLEMAP} GOOGLEMAP - Parámetros de conexión de Google Map
   * @property {CONNECT_ALGOLIA} ALGOLIA - Parámetros de conexión de Algolia
   * @property {CONNECT_WS} WS - Parámetros de conexión con el webserver wifi
   */
  CONNECT: {
    APP: {
      AZCLOUD_BASE_URL: process.env.VUE_APP_AZCLOUD_BASE_URL,
      AZCLOUD_API_URL: process.env.VUE_APP_AZCLOUD_API_URL,
      AZCLOUD_SOCKET_PATH: process.env.VUE_APP_AZCLOUD_SOCKET_PATH,
      AZSUPPORT_API_URL: process.env.VUE_APP_AZSUPPORT_API_URL,
      AZSUPPORT_API_KEY: process.env.VUE_APP_AZSUPPORT_API_KEY,
      AZSUPPORT_API_KONG_GATEWAY: process.env.VUE_APP_AZSUPPORT_API_KONG_GATEWAY,
      AZSUPPORT_API_MSMULTIMEDIA: process.env.VUE_APP_AZSUPPORT_API_MSMULTIMEDIA,
      
    },
    GOOGLEMAP: {
      API_URL: process.env.VUE_APP_GOOGLEMAP_API_URL,
      KEY: process.env.VUE_APP_GOOGLEMAP_KEY,
    },
    ALGOLIA: {
      CLIENT_ID: process.env.VUE_APP_ALGOLIA_CLIENT_ID,
      API_KEY: process.env.VUE_APP_ALGOLIA_API_KEY,
    },
    WS: process.env.VUE_APP_WS_API_URL,
    ROLLBAR: {
      TOKEN: process.env.VUE_APP_ROLLBAR_TOKEN,
      ACTIVE: process.env.VUE_APP_ROLLBAR_ACTIVE,
    },
    GET_WIFIS: {
      USER: process.env.VUE_APP_GET_WIFIS_USER,
      PASS: process.env.VUE_APP_GET_WIFIS_PASS,
    },
  },

  /**
   * LANGUAGE
   *
   * @constant {Object} LANGUAGE - Parámetros de configuración de los idiomas
   * @property {String} DEFAULT - Idioma seleccionado por defecto
   * @property {String} FALLBACK - Idioma utilizado si no se encuentra una traducción en el idioma seleccionado
   */
  LANGUAGE: {
    DEFAULT: process.env.VUE_APP_DEFAULT_LANGUAGE || "en",
    FALLBACK: process.env.VUE_APP_DEFAULT_LANGUAGE || "en",
  },

  /**
   * LANGUAGE
   *
   * @constant {Object} LANGUAGES - Listado de idiomas disponibles
   */
  LANGUAGES: {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    pt: 'Português',
  },

  /**
   * UNITS
   * Tipo de unidad de temperatura del usuario recibido en el backend
   *
   * @constant {Object} UNITS - Valores de las unidades de temperatura
   */
  UNITS: {
    CELSIUS: 0,
    FARENHEIT: 1,
  },

  /**
   * TEMP_UNITS
   * Tipo de unidad de temperatura utilizada para enviarnos las temperaturas desde el backend
   *
   * @constant {Object} TEMP_UNITS - Nombres de las unidades de temperatura
   */
  TEMP_UNITS: {
    CELSIUS: "celsius",
    FARENHEIT: "fah",
  },

  /**
   * @constant {Object} - Links de las App stores
   * @property {String} IOS - Links de la aplicación para iOS
   * @property {String} ANDROID - Links de la aplicación para Android
   */
  STORE: {
    IOS: process.env.VUE_APP_STORE_IOS,
    ANDROID: process.env.VUE_APP_STORE_ANDROID,
  },

  /**
   * @constant {Object} REGEX Regular expressions to validate forms
   * @property {String} EMAIL - Regular expression of email
   * @property {String} NAME - Regular expression of name
   */
  REGEX: {
    EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
    NAME: /^[a-zA-Z0-9 ñàâäáèêëéìîïíòôóöùûüúÿçÑÀÂÄÁÈÉÊËÉÎÏÍÔÓÖÙÛÜÚŸÇ-\s_,.!¿?()]{2,35}$/i,
    MAC_DIGITS: /([a-f0-9]{2})([a-f0-9]{2})/i,
    MAC_ADDRESS: /^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i,
    ZONE_NAME:
      /^[!\-+., @#*%&/():;¿?_ÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÄËÏÖÜÃÕÑÇA-Záéíóúàèìòùâêîôûäëïöüãõñça-z0-9]{1,11}$/i,
  },

  NETWORK: {
    ONLINE: "online",
    UNKNOWN: "Unknown connection",
    ETHERNET: "Ethernet connection",
    WIFI: "WiFi connection",
    CELL_2G: "Cell 2G connection",
    CELL_3G: "Cell 3G connection",
    CELL_4G: "Cell 4G connection",
    CELL_5G: "Cell 5G connection",
    CELL: "Cell generic connection",
    NONE: "offline",
  },

  RATE_PERIODS: {
    HIGH: 1,
    MID: 2,
    LOW: 3,
  },

  /**
   * @constant {number} MAX_TIME_DETECTING_SYSTEM - Máximo tiempo de espera de obtener respuesta detectando sistemas
   */
  TIMEOUT: {
    UPDATE_DEVICE_DELAY: 500,
    MAX_TIME_DETECTING_SYSTEM: 120000,
  },
};
