/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vswings': {
    width: 26,
    height: 28,
    viewBox: '0 0 26 28',
    data: '<path pid="0" d="M13.62.325c.3.2.3.7 0 .9l-10 9.9c1.1 1.5 1.1 3.6 0 5.1l9.9 9.8c.3.2.3.7 0 .9-.3.2-.7.2-.9 0l-10.6-10.6c-1.5-1.5-1.5-3.9 0-5.4l10.7-10.6c.3-.3.7-.3.9 0zm-3.2 13.9c-.4 0-.6-.3-.6-.6s.3-.6.6-.6h8.6c.4 0 .6.3.6.6s-.3.6-.6.6h-8.6zm-.5 2.7c-.3-.1-.6-.4-.5-.8.1-.4.4-.6.8-.5l8.3 2.1c.3.1.6.4.5.8-.1.4-.4.6-.8.5l-8.3-2.1zm-1.3 2.5c-.3-.2-.4-.6-.3-.9.2-.3.6-.4.9-.3l7.6 4c.3.2.4.6.3.9-.2.3-.6.4-.9.3l-7.6-4zm1.7-7.9c-.3.1-.7-.1-.8-.5-.1-.3.1-.7.5-.8l8.3-2.1c.3-.1.7.1.8.5.1.3-.1.7-.5.8l-8.3 2.1zm12.2 7.6c.8 0 1.5.2 1.9.6.5.3.7.9.7 1.7 0 .5-.1.8-.3 1.3-.2.5-.6.9-1 1.5l-1.8 1.9h3.5v1.1h-5.6v-1.1l2.6-2.9c.3-.3.6-.7.8-1 .2-.3.2-.5.2-.7 0-.3-.1-.7-.2-.8-.2-.2-.5-.3-.8-.3-.3 0-.7.1-.9.3-.2.2-.3.6-.3 1h-1.6c0-.5.1-.9.3-1.4.2-.5.6-.7 1-.9.5-.2 1-.3 1.5-.3zm-5.1-15.1c.2.3 0 .7-.3.9l-7.6 4c-.3.2-.7 0-.9-.3-.2-.3 0-.7.3-.9l7.6-4c.4-.1.7 0 .9.3z" _fill="#000" fill-rule="nonzero"/>'
  }
})
