import Vue from "vue";
import VueRouter from "vue-router";
import SupportView from "@/pages/SupportView.view.vue";
import SupportThemes from "@/pages/SupportThemes.view.vue";
import SupportDocs from "@/pages/SupportDocs.view.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'support',
    },
  },
  {
    path: "/support/",
    name: "support",
    component: SupportView,
  },
  {
    path: "/support/:categoryId",
    name: "supportThemes",
    component: SupportThemes,
  },
  {
    path: "/support/:categoryId/content/:reference",
    name: "supportDocs",
    component: SupportDocs,
    props: true
  },
  // Routes not found
  {
    path: '*',
    redirect: {
      name: 'support',
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
