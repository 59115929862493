import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import SvgIcon from "vue-svgicon";
import AzAvatar from "@/components/Avatar/Avatar.vue";
import AzButton from "@/components/Button/Button.vue";
import AzCell from "@/components/Cell/Cell.vue";
import AzCellGroup from "@/components/CellGroup/CellGroup.vue";
import AzCheckbox from "@/components/Checkbox/Checkbox.vue";
import AzCheckboxGroup from "@/components/CheckboxGroup/CheckboxGroup.vue";
import AzCol from "@/components/Col/Col.vue";
import AzContainer from "@/components/Container/Container.vue";
import AzFeedback from "@/components/Feedback/Feedback.vue";
import AzFieldset from "@/components/Fieldset/Fieldset.vue";
import AzInput from "@/components/Input/Input.vue";
import AzLoading from "@/components/Loading/Loading.vue";
import AzRow from "@/components/Row/Row.vue";
import AzSelect from '@/components/Select';
import AzSwitch from "@/components/Switch/Switch.vue";
import AzTabBar from "@/components/TabBar/TabBar.vue";
import AzTabBarItem from "@/components/TabBarItem/TabBarItem.vue";
import AzSectionInfo from "@/components/SectionInfo";
import Header from "@/components/Header.component.vue";
import NavBar from "@/components/NavBar.component.vue";
import Toast from "@/components/Toast"
import InstantSearch from 'vue-instantsearch';

import "@/assets/icons/sprite";
import '@/design/main.scss'

Vue.use(SvgIcon, {
  tagName: "az-icon",
  classPrefix: "az",
});
Vue.use(Toast);
Vue.use(InstantSearch);

Vue.component("AzAvatar", AzAvatar);
Vue.component("AzButton", AzButton);
Vue.component("AzCell", AzCell);
Vue.component("AzCellGroup", AzCellGroup);
Vue.component("AzCheckbox", AzCheckbox);
Vue.component("AzCheckboxGroup", AzCheckboxGroup);
Vue.component("AzCol", AzCol);
Vue.component("AzContainer", AzContainer);
Vue.component("AzFeedback", AzFeedback);
Vue.component("AzFieldset", AzFieldset);
Vue.component("AzInput", AzInput);
Vue.component("AzLoading", AzLoading);
Vue.component("AzRow", AzRow);
Vue.component('AzSelect', AzSelect);
Vue.component("AzSwitch", AzSwitch);
Vue.component("AzTabBar", AzTabBar);
Vue.component("AzTabBarItem", AzTabBarItem);
Vue.component("AzSectionInfo", AzSectionInfo);
Vue.component("NavBar", NavBar);
Vue.component("Header", Header);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
