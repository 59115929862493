import CONSTANTS from '@/constant';

const openLink = (link, cbClosed) => {
  console.log('Open: ', link);
  if (CONSTANTS.IS_MOBILE === CONSTANTS.PLATFORM.IOS) {
    // eslint-disable-next-line
    SafariViewController.isAvailable(available => {
      if (available) {
        console.log('Safari available');
        // eslint-disable-next-line
        SafariViewController.show(
          {
            url: link,
            transition: 'curl',
          },
          result => {
            if (result.event === 'opened') {
              console.log('opened');
            } else if (result.event === 'loaded') {
              console.log('loaded');
            } else if (result.event === 'closed') {
              console.log('closed');
              if(cbClosed !== undefined) {
                cbClosed();
              }
            }
          },
          error => {
            console.error(error);
          }
        );
      } else {
        // If safari isn't available
        // eslint-disable-next-line no-undef
        cordova.InAppBrowser.open(link, '_system', 'location=no');
      }
    });
  } else if(CONSTANTS.IS_MOBILE === CONSTANTS.PLATFORM.ANDROID) {
    // In web and Android
    // eslint-disable-next-line no-undef
    cordova.InAppBrowser.open(link, '_system', 'location=no');

  } else {
    // web
    window.open(link, '_blank');
    if(cbClosed !== undefined) {
      // eslint-disable-next-line no-inner-declarations
      function focusEvent() {
        console.log('Focus: ');
        window.removeEventListener('focus', focusEvent);
        cbClosed();
      }
      window.addEventListener("focus", focusEvent);
    }


  }

  return false;
};

export default openLink;
