<template lang="pug">
.support-view(:class="{ scrolled }")
  //- Barra de navegación
  nav-bar(:title="$t('support.title')", @click-left="back")
    az-icon.color-gray3(
      name="left",
      scale="1.1",
      slot="left",
    )

  .main(@scroll="handleScroll")

    //- Cuando no recibimos secciones
    EmptyState(:showError="!theme && !isLoading")

    //- Listado de secciones dentro de una categoría
    .support__theme.margin-bottom-triple(v-if="theme")
      .support__theme__header {{ themeTitle }}

      .support__theme__units(v-if="theme.subthread.length")
        .support__theme__unit(
          v-for="unit in theme.subthread",
          :key="unit.reference"
        )
          az-cell(title-width="90%", :title="unit.title")

          .support__theme__subUnit(v-if="unit.subthread")
            az-cell.padding-left(
              v-for="subUnit in unit.subthread",
              title-width="90%",
              :key="subUnit.reference",
              :title="subUnit.title",
              @click="showDocs(subUnit.reference, subUnit.docs)",
              is-link
            )
</template>

<script>
import ScrollMixin from "@/mixins/Scroll.mixin";
import EmptyState from "./EmptyState.view.vue";
import SupportService from '@/services/support.service';
import i18n, { setLanguage } from '@/i18n'
import CONSTANTS from "@/constant";

export default {
  mixins: [ScrollMixin],

  components: {
    EmptyState
  },

  data() {
    return {
      query: "",
      theme: null,
      themeTitle: this.$route.params.categoryTitle,
      categoryId: this.$route.params.categoryId,
      lang: this.$route.query.lang ? this.$route.query.lang : i18n.locale,
      isLoading: false,
      supportError: null,
    };
  },

  computed: {     
  },

  methods: {
    /**
     * Redirecciona a la documentación (SupportDocs)
     */
    showDocs(reference, docs) {
      this.$router.push({ name: "supportDocs", params: { reference, docs, categoryTitle: this.themeTitle } });
    },

    /**
     * Vuelve atrás (Support View)
     */
    back() {
      this.$router.push({ name: "support" });
    },

    async getSections() {
      try {
        this.isLoading = true;

        // Compruebo si el idioma está disponible, sino asigno el inglés
        // eslint-disable-next-line no-prototype-builtins
        if (!CONSTANTS.LANGUAGES.hasOwnProperty(this.lang)) {
          setLanguage('en')
        } else {
          setLanguage(this.lang)
        }

        // Si no existe el título como parámetro de la URL
        // entonces pido todas las categorías para buscar el título      
        if (!this.themeTitle) {
          const support = await SupportService.getSupportData();
          const categories = support?.faqsTree
          const category = categories[this.categoryId]
          this.themeTitle = category?.title
        }

        if (!this.theme) {
          this.$toast.loading({
            closeOnClick: false, // Forzamos que no se pueda cerrar la carga al tocar la pantalla
          });
    
          // Si exite los parámetros categoryId, entonces pido los datos de las secciones
          if (this.categoryId) {
            this.theme = await SupportService.getSupportThemes(this.categoryId)
          }
          
          this.$toast.clear();
        }
        this.isLoading = false;
      } catch(err) {
        console.log(err)
        this.$toast.clear();
        this.isLoading = false;
      }
    }
  },

  async created() {
    await this.getSections()
  }
};
</script>