<template lang="pug">
.support-view(:class="{ scrolled }")
  //- Barra de navegación
  nav-bar(:title="$t('support.title')")
    //- az-icon.color-gray3(
    //-   name="left",
    //-   scale="1.1",
    //-   slot="left",
    //-   v-if="theme || query"
    //- )
    //- az-icon.color-gray3(name="menu", scale="1", slot="left", v-else)

  //- Barra de busqueda
  az-cell-group.support-view__search
    az-input(
      left-icon="search",
      :placeholder="$t('support.search')",
      v-model.trim="query",
      clearable
    )

  //- Resultados de la busqueda
  .main.margin-top-double(v-if="query && searchClient")
    az-cell-group.support-view__results
      ais-instant-search(:search-client="searchClient", :index-name="index")
        //- Configuración de la librería
        //-, :filters="filters")
        ais-configure(:query="query") 

        //- Listado de coincidencias
        ais-hits
          template(slot="item", slot-scope="{ item }")
            az-cell(
              title-width="80%",
              :title="getItem(item)",
              @click="showDocs(item)",
              is-link
            )

  //- Cuando no recibimos categorías
  .main.margin-top-double(@scroll="handleScroll")
    EmptyState(:showError="!isLoading && !theme && !faqsTree")

    //- Listado de categorías principales
    .support__category(v-if="faqsTree && !query", @scroll="handleScroll")
      az-row.margin-bottom-triple(grid, v-if="!theme")
        az-col(cols="6", v-for="(category, key) in faqsTree", :key="key")
          transition(name="fromBottom", appear)
            .supportButton(
              :style="`transition-delay: ${0.3 + key * 0.08}s`",
              @click="showTheme(category.title, key)"
            )
              .supportButton__icon
                img(:src="category.icon" v-if="category.icon")
                az-icon(name="device", scale="1.3", v-else)
              .supportButton__title
                | {{ category.title }}

</template>

<script>
import ScrollMixin from "@/mixins/Scroll.mixin";
import algoliasearch from "algoliasearch/lite";
import SupportService from '@/services/support.service';
import EmptyState from "./EmptyState.view.vue";
import CONSTANTS from "@/constant";
import i18n, { setLanguage } from '@/i18n'

export default {

  mixins: [ScrollMixin],

  components: {
    EmptyState
  },

  data() {
    return {
      // facets: 'categories:"Cell Phones"',
      // categories:"Cell Phones"
      filters: '',
      query: "",
      theme: null,
      faqsTree: null,
      lang: this.$i18n.locale,
      isLoading: false,
      supportError: null,
    };
  },

  computed: {

    index() {
      return 'digital_contents';
    },

    searchClient() {
      // console.log('lang: ', this.lang)
      return algoliasearch(
        CONSTANTS.CONNECT.ALGOLIA.CLIENT_ID,
        CONSTANTS.CONNECT.ALGOLIA.API_KEY
      );
    },
  },

  methods: {
    /**
     * Obtener secciones digitales
     */
    async getFaqs() {
      try {
        const support = await SupportService.getSupportData();
        this.faqsTree = support?.faqsTree
      } catch (error) {    
        console.log(error)
      }
    },

    /**
     * Muestra el popup con la documentación
     */
    showTheme(categoryTitle, categoryId) {
      console.log('showTheme: ',categoryTitle, categoryId)
      this.$router.push({ name: "supportThemes", params: { categoryTitle, categoryId } });
    },

    async showDocs(data){
      const docs = null
      const reference = data.az_iso
      const categoryTitle = this.getItem(data)
      this.$router.push({ name: "supportDocs", params: { reference, docs, categoryTitle } });
    },

    getItem(elem) {
      // const locale = `name_${this.lang}`;
      // console.log('string: ', elem[locale])
      return elem[`name_${this.lang}`]
    },
  },

  async mounted() {
    this.isLoading = true;
    this.$toast.loading({
      closeOnClick: false, // Forzamos que no se pueda cerrar la carga al tocar la pantalla
    });

    // Compruebo el query string lang para cambiar el idioma si es diferente
    setLanguage(this.$route.query.lang ? this.$route.query.lang : i18n.locale)

    // Obtengo las secciones digitales y guardo en VUEX
    await this.getFaqs();

    this.$toast.clear();
    this.isLoading = false;
  },
};
</script>

<style lang="scss">
.supportButton {
  @include shadow(3);

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // min-width: size(3.5);
  height: size(5.5);
  padding: size(1.5) 0 half();
  color: color(content);
  cursor: pointer;
  background-color: color(white);
  border-radius: $border-radius;
  transition: all 0.3s;

  &__icon {
    color: color(gray4);
  }

  &__title {
    display: flex;
    align-items: center;
    max-width: calc(100% - #{simple()});
    height: 2.4rem;
    overflow: hidden;
    line-height: 1.2rem;
    color: color(gray3);
    text-align: center;
    text-overflow: ellipsis;
  }
}

.support-view {
  &__search {
    position: absolute;
    top: calc(95px + #{half()});
    left: half();
    z-index: 100;
    width: calc(100% - #{simple()});

    .az-input__left-icon {
      margin-right: 0.75rem;
      margin-left: 0;
    }
  }

  &__results {
    height: 100%;
    margin-bottom: 0 !important;
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar{ display: none; }
  }

  > .van-nav-bar {
    height: calc(130px + env(safe-area-inset-top));

    .van-nav-bar__left,
    .van-nav-bar__right {
      top: 0;
      bottom: initial;
    }
  }

  > .main {
    padding-top: size(5);
  }
}

.supportButton__icon {
  img {
    margin-top: -0.7rem;
    height: 70px;
  }
}

@media screen and (max-width: 400px) {
  .supportButton__icon {
    img {
      height: 50px;
    }
  }
}

.ais-Hits-list,
.ais-Hits-item {
  margin: 0;
}

.ais-Hits-item:not(:last-of-type) {
  border-bottom: 2px solid color(gray8);
}

.support__theme {
  margin-top: calc(-1 * simple());
  &__header {
    margin-top: quarter();
    margin-bottom: half();
    margin-left: quarter();
    font-weight: font-weight(bold);
    color: color(gray1);
  }

  &__units {
    @include shadow(3);

    overflow: hidden;
    background-color: color(gray-7);
    border-radius: $border-radius;
  }

  &__unit:not(:last-of-type) {
    border-bottom: 2px solid color(gray8);
  }

  &__subUnit {
    border-top: 1px solid color(gray6);
    border-bottom: 1px solid color(gray6);

    .az-cell {
      background-color: color(gray7);

      &::after {
        border-color: color(gray6) !important;
      }
    }
  }
}

.fromBottom-enter-active,
.fromBottom-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fromBottom-enter,
.fromBottom-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
