/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hswings': {
    width: 27,
    height: 26,
    viewBox: '0 0 27 26',
    data: '<path pid="0" d="M26.75 12.825c-.2.3-.7.3-.9 0l-9.9-10c-1.5 1.1-3.6 1.1-5.1 0l-9.8 9.9c-.2.3-.7.3-.9 0-.2-.3-.2-.7 0-.9l10.6-10.7c1.5-1.5 3.9-1.5 5.4 0l10.6 10.7c.3.4.3.8 0 1zm-13.9-3.2c0-.4.3-.6.6-.6s.6.3.6.6v8.6c0 .4-.3.6-.6.6s-.6-.3-.6-.6v-8.6zm-2.7-.5c.1-.3.4-.6.8-.5.3.1.6.4.5.8l-2.1 8.3c-.1.3-.4.6-.8.5-.3-.1-.6-.4-.5-.8l2.1-8.3zm-2.5-1.2c.2-.3.6-.4.9-.3.3.1.4.6.3.9l-4 7.6c-.2.3-.6.4-.9.3-.3-.2-.4-.6-.3-.9l4-7.6zm7.9 1.6c-.1-.3.1-.7.5-.8.3-.1.7.1.8.5l2.1 8.3c.1.3-.1.7-.5.8-.3.1-.7-.1-.8-.5l-2.1-8.3zm8.4 7.6c.8 0 1.5.2 1.9.6.5.3.7.9.7 1.7 0 .5-.1.8-.3 1.3-.2.5-.6.9-1 1.5l-1.8 1.9h3.5v1.1h-5.6v-1.1l2.6-2.9c.3-.3.6-.7.8-1 .2-.3.2-.5.2-.7 0-.3-.1-.7-.2-.8-.2-.2-.5-.3-.8-.3-.3 0-.7.1-.9.3-.2.2-.3.6-.3 1h-1.6c0-.5.1-.9.3-1.4.2-.5.6-.7 1-.9.4-.2.9-.3 1.5-.3zm-4.6-9l2.7 5.2c-.4.1-.8.2-1.2.4l-2.6-5c-.2-.3 0-.7.3-.9.2-.1.6 0 .8.3z" _fill="#000" fill-rule="nonzero"/>'
  }
})
