<template lang="pug">
.emptyState.margin-top-quadruple(v-if="showError")
  img.emptyState__img(src="@/assets/img/error.svg")

  .emptyState__title {{ $t('errors.default.title') }}
  .emptyState__message {{ $t('errors.default.message') }}
</template>

<script>

export default {
  props: {
    showError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },


  methods: {
    
  },

  async created() {

  }
};
</script>