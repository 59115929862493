/* eslint-disable */
require('./acs')
require('./add-zones')
require('./addDevice')
require('./auto-bold-lock')
require('./auto-bold')
require('./auto')
require('./blocks')
require('./bluetooth')
require('./calendar')
require('./cancel')
require('./chance-light-rain-dark')
require('./chance-light-rain')
require('./chance-rain-dark')
require('./chance-rain')
require('./chance-sleet-dark')
require('./chance-sleet')
require('./chance-snow-dark')
require('./chance-snow')
require('./chance-storm-dark')
require('./chance-storm')
require('./chart')
require('./check')
require('./clear-day 2')
require('./clear-day-dark')
require('./clear-day')
require('./clear-night-dark')
require('./clear-night')
require('./clear')
require('./clipboard')
require('./close')
require('./cloud')
require('./cloudy-dark')
require('./cloudy')
require('./Combined Shape')
require('./comfort')
require('./connect')
require('./cool_air-bold-lock')
require('./cool_air-bold')
require('./cool_air')
require('./cool_comb-bold-lock')
require('./cool_comb-bold')
require('./cool_comb')
require('./cool_radiant-bold-lock')
require('./cool_radiant-bold')
require('./cool_radiant')
require('./cool-bold-lock')
require('./cool-bold')
require('./cool-dark')
require('./cool')
require('./desktop')
require('./details-old')
require('./details')
require('./device')
require('./down-solid')
require('./down')
require('./dry-bold-lock')
require('./dry-bold')
require('./dry-solid')
require('./dry')
require('./eco-a_p')
require('./eco-a_pp')
require('./eco-a')
require('./eco-manual')
require('./eco-off')
require('./eco-small-a_p')
require('./eco-small-a_pp')
require('./eco-small-a')
require('./eco-small-manual')
require('./eco-small-off')
require('./eco')
require('./ecobee')
require('./edit')
require('./edit2')
require('./edit3')
require('./elipsis')
require('./email')
require('./emergencyHeat-bold-lock')
require('./emergencyHeat-bold')
require('./emergencyHeat')
require('./error-check')
require('./error-modal')
require('./Error')
require('./etape-air')
require('./etape-combined')
require('./etape-radiant')
require('./ethernet')
require('./exit')
require('./fan-bold-lock')
require('./fan-bold')
require('./fan-off')
require('./fan-on')
require('./fan')
require('./fan0')
require('./fan1')
require('./fan2')
require('./fan3')
require('./fan4')
require('./fan5')
require('./fan6')
require('./fan7')
require('./fan8')
require('./fog-dark')
require('./fog-wind-dark')
require('./fog-wind')
require('./fog')
require('./gear')
require('./group-1')
require('./group-10')
require('./group-11')
require('./group-12')
require('./group-13')
require('./group-14')
require('./group-15')
require('./group-16')
require('./group-17')
require('./group-18')
require('./group-19')
require('./group-2')
require('./group-20')
require('./group-3')
require('./group-4')
require('./group-5')
require('./group-6')
require('./group-7')
require('./group-8')
require('./group-9')
require('./groups')
require('./hail-dark')
require('./hail')
require('./hard-wind-dark')
require('./hard-wind')
require('./hauto')
require('./hazy-dark')
require('./hazy')
require('./heat_air-bold-lock')
require('./heat_air-bold')
require('./heat_air')
require('./heat_comb-bold-lock')
require('./heat_comb-bold')
require('./heat_comb')
require('./heat_radiant-bold-lock')
require('./heat_radiant-bold')
require('./heat_radiant')
require('./heat-bold-lock')
require('./heat-bold')
require('./heat')
require('./hfixed')
require('./holidays')
require('./home')
require('./honeywell')
require('./hswing')
require('./hswings')
require('./iaq')
require('./icon-info')
require('./Info-modal')
require('./info')
require('./integration')
require('./ionized-auto')
require('./ionized-off')
require('./ionized-on')
require('./ionized')
require('./lap')
require('./large')
require('./leave')
require('./left')
require('./less')
require('./light-rain-dark')
require('./light-rain')
require('./light-snow-dark')
require('./light-snow')
require('./link')
require('./list')
require('./location')
require('./lock')
require('./medium-rain-dark')
require('./medium-rain')
require('./menu-bold')
require('./menu')
require('./mobile')
require('./mode')
require('./more')
require('./nest')
require('./network')
require('./night')
require('./OFF')
require('./order-blocks')
require('./order-rows')
require('./order')
require('./outdoor')
require('./palm')
require('./partly-cloudy-day 2')
require('./partly-cloudy-day-dark')
require('./partly-cloudy-day')
require('./partly-cloudy-night-dark')
require('./partly-cloudy-night')
require('./password-hide')
require('./password')
require('./path')
require('./phone')
require('./power-bold')
require('./power-lock')
require('./power')
require('./powerfull-off')
require('./powerfull-old')
require('./powerfull-on')
require('./powerfull')
require('./rain-dark')
require('./rain')
require('./reset')
require('./right')
require('./rows')
require('./salon')
require('./save')
require('./scene-1')
require('./scene-10')
require('./scene-11')
require('./scene-12')
require('./scene-13')
require('./scene-14')
require('./scene-15')
require('./scene-16')
require('./scene-17')
require('./scene-18')
require('./scene-19')
require('./scene-2')
require('./scene-20')
require('./scene-21')
require('./scene-22')
require('./scene-23')
require('./scene-24')
require('./scene-3')
require('./scene-4')
require('./scene-5')
require('./scene-6')
require('./scene-7')
require('./scene-8')
require('./scene-9')
require('./scenes-bold')
require('./scenes')
require('./schedules-bold')
require('./schedules')
require('./search')
require('./settings-block')
require('./settings')
require('./signal')
require('./signal1')
require('./signal2')
require('./signal3')
require('./sleep-30')
require('./sleep-60')
require('./sleep-90')
require('./sleep-off')
require('./sleep-small-30')
require('./sleep-small-60')
require('./sleep-small-90')
require('./sleep-small-off')
require('./sleet-dark')
require('./sleet')
require('./slider')
require('./small')
require('./snow')
require('./sofa')
require('./speed-2')
require('./speed-3')
require('./speed-auto')
require('./speed-block')
require('./speed')
require('./speedbar-0')
require('./speedbar-1')
require('./speedbar-2')
require('./speedbar-3')
require('./speedbar-4')
require('./speedbar-5')
require('./speedbar-6')
require('./speedbar-7')
require('./speedbar-8')
require('./speedbar')
require('./stop-bold-lock-old')
require('./stop-bold-lock')
require('./stop-bold')
require('./stop-lock copia')
require('./stop')
require('./success-modal')
require('./switch')
require('./tank')
require('./temp')
require('./thunderstorm-dark')
require('./thunderstorm')
require('./time-30')
require('./time-60')
require('./time-90')
require('./time-off')
require('./time-small-30')
require('./time-small-60')
require('./time-small-90')
require('./time-small-off')
require('./tools')
require('./tornado-dark')
require('./tornado')
require('./trash')
require('./unit-external')
require('./up-solid')
require('./up')
require('./update-old')
require('./update')
require('./upgrade-icon')
require('./user-bold')
require('./user-fill')
require('./user')
require('./vauto')
require('./velocidad-block')
require('./vfixed')
require('./vswing')
require('./vswings')
require('./warning-modal-old')
require('./warning-modal')
require('./warning')
require('./widgets')
require('./wind-dark')
require('./wind')
require('./zones')
