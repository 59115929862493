<template lang="pug">
.support-docs(:class="{ scrolled }")
  //- Barra de navegación
  nav-bar(:title="category", left-arrow, @click-left="back")
    az-icon(name="left", scale="1.1", slot="left")
    az-icon.margin-right-half(
      name="scenes",
      scale="1.1",
      slot="right",
      @click="showVideo()",
      v-if="video"
    )

  .main(@scroll="handleScroll")
    .support__data.margin-top-half.margin-bottom-triple(v-html="docData.text", v-if="docData && !isLoading")

    EmptyState(:showError="!docData && !isLoading")

  Popup.van-popup--video(
    v-model="popupVideo",
    closeOnEsc,
    closeOnClickMask,
    :closeButton="false",
    @hide="popupVideo = false",
    v-if="popupVideo"
  )
    .videoWrapper
      iframe(
        width="560",
        height="315",
        :src="video",
        frameborder="0",
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        allowfullscreen
      )
</template>

<script>
import ScrollMixin from "@/mixins/Scroll.mixin";
// import OpenLinkMixin from '@/mixins/OpenLink.mixin';
// import { mapGetters, mapActions } from "vuex";
import Popup from "@/components/Popup/Popup.vue";
import EmptyState from "./EmptyState.view.vue";
import SupportService from '@/services/support.service';
import CONSTANTS from "@/constant";
import i18n, { setLanguage } from '@/i18n'

export default {
  mixins: [ScrollMixin], // OpenLinkMixin],
  components: { Popup, EmptyState },
  data() {
    return {
      docShow: true,
      isLoading: false,
      popupVideo: false,
      docData: this.$route.params.docs,
      lang: this.$route.query.lang ? this.$route.query.lang : i18n.locale
    };
  },

  watch: {
    reference() {
      setTimeout(async () => {
        await this.getDocs();
        // this.$forceUpdate()
      }, 0)
    }
  },

  computed: {

    reference(){
      return this.$route.params.reference;
    },

    category() {
      if (!this.docData) return this.$i18n.t("support.title");
      return this.docData.category;
    },

    video() {
      if (!this.docData) return "";
      return this.docData.video;
    },
  },
  methods: {
    /**
     * Muestra el popup con el vídeo
     */
    showVideo() {
      this.popupVideo = true;
    },

    /**
     * Vuelve a la vista previa
     */
    back() {
      // console.log(this.$route.params)
      if (!this.$route.params.categoryId) {
        this.$router.push({ name: "support" });
        return;
      } else {
        this.$router.push({
          name: "supportThemes",
          params: { categoryId: this.$route.params.categoryId, categoryTitle: this.$route.params.categoryTitle },
        });
      }
    },

    /**
     * Crea el enlace en imágenes con la clase clicable
     */
    initImageStyle() {
      const images = [...document.getElementsByClassName('clicable')]

      for (var i = 0; i < images.length; ++i) {
        if (images[i]) {
          var img = images[i];
          var a = document.createElement('a');  // Make a new anchor.
          if (img) {
            a.href = img.src;                   // Point it at the image source.
            a.target = '_blank'                 // Add to open in new window.
            img.parentNode.replaceChild(a, img);  // Replace the image with the anchor.
            a.appendChild(img);                   // Make the image a child of the anchor.
            }
            
          }
        }
    },

    /**
     * Obtiene los datos a renderizar del documento
     */
    async getDocs() {
      try {
        this.isLoading = true;
        this.$toast.loading({
          closeOnClick: false, // Forzamos que no se pueda cerrar la carga al tocar la pantalla
        });
  
        if (this.reference) {
          // Compruebo si el idioma está disponible, sino asigno el inglés
          // eslint-disable-next-line no-prototype-builtins
          if (!CONSTANTS.LANGUAGES.hasOwnProperty(this.lang)) {
            setLanguage('en')
          } else {
            setLanguage(this.lang)
          }

          this.docData = await SupportService.getSupportContent(this.reference)
        }
        // console.log('docData: ', this.docData)
  
        this.$toast.clear();
        this.isLoading = false;
      } catch(err) {
        console.log(err)
        this.$toast.clear();
        this.isLoading = false;
      }
    },
  },

  async created() {
    // console.log('created: ', this.$route.params)
    if (!this.docData) {
      await this.getDocs()
    }
  },
  
  mounted() {
    // console.log('DOCS: ', this.docData)
    setTimeout(() => {
      this.initImageStyle()
    }, 0)
  }
};
</script>

<style lang="scss">
.nav-bar__title {
  max-width: calc(100% - #{simple()});
  text-overflow: ellipsis
}
.support-docs {
  .support__data {
    max-width: 75%;
    margin: 0 auto;
  }
}

.van-popup--video {
  // width: 90%;
  // max-width: 600px;
  border-radius: $border-radius;

  .az-popup__content {
    min-width: 35rem;
  }
}

.videoWrapper {
  position: relative;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 */
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@include media(palm) {
  .nav-bar__title {
    padding: 0 3.5rem 0 3.5rem;
    text-overflow: ellipsis;
    overflow: hidden
  }

  .support-docs {
    .support__data {
      max-width: 550px;
    }
  }
  .van-popup--video {
    .az-popup__content {
      min-width: 22rem;
    }
  }
}
</style>
