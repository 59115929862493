/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-block': {
    width: 32,
    height: 29,
    viewBox: '0 0 32 29',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M2.774 1.4h1.8v11.5h-1.8zM20.774 1.4h1.8v12.8h-1.8zM11.774 1.4h1.8v3.5h-1.8zM2.774 17.8h1.8v7.5h-1.8zM9.974 6.4h5.5v1.8h-5.5zM.974 14.4h5.5v1.8h-5.5zM29.015 20.883V19.01c0-1.873-1.498-3.37-3.496-3.37s-3.496 1.497-3.371 3.37v1.748c-.75 0-1.374.5-1.374 1.249v4.62c0 .749.625 1.373 1.374 1.373h6.992c.749 0 1.373-.624 1.373-1.373v-4.62c-.25-.624-.874-1.124-1.498-1.124zm-2.747 4.745c0 .374-.375.749-.874.749-.375 0-.874-.25-.874-.75v-2.122c0-.374.25-.749.874-.749.5 0 .874.375.874.75v2.122zm.624-6.743v1.998h-2.996v-1.998c0-.749.749-1.373 1.498-1.373.874 0 1.623.5 1.498 1.373zM11.774 9.8h1.8v15.5h-1.8z"/></g>'
  }
})
