/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'velocidad-block': {
    width: 33,
    height: 28,
    viewBox: '0 0 33 28',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M4.433 7.4h-1.5c-1.7 0-2.8 1-2.8 2.3V23c0 1.4 1.3 2.4 2.8 2.4h1.5c1.5 0 2.8-1.1 2.8-2.4V9.7c0-1.3-1.3-2.3-2.8-2.3zm1.2 15.6c0 .5-.5 1-1.2 1h-1.5c-.6 0-1.2-.4-1.2-1V9.7c0-.6.4-1 1.2-1h1.5c.6 0 1.2.4 1.2 1V23zM13.333 4.4h-1.5c-1.7 0-2.8 1.1-2.8 2.7v15.5c0 1.6 1.3 2.8 2.8 2.8h1.5c1.5 0 2.8-1.3 2.8-2.8V7.1c0-1.5-1.3-2.7-2.8-2.7zm1.2 18.2c0 .6-.5 1.2-1.2 1.2h-1.5c-.6 0-1.2-.5-1.2-1.2V7.1c0-.7.4-1.2 1.2-1.2h1.5c.6 0 1.2.5 1.2 1.2v15.5zM20.833 1.9h1.5c.6 0 1.2.5 1.2 1.2v11.05h1.6V3.1c0-1.6-1.3-2.8-2.8-2.8h-1.5c-1.7 0-2.8 1.2-2.8 2.8v19.5c0 1.6 1.3 2.8 2.8 2.8h.7v-1.6h-.7c-.6 0-1.2-.5-1.2-1.2V3.1c0-.7.4-1.2 1.2-1.2z"/><path pid="1" d="M30.881 20.79v-1.897c0-1.897-1.518-3.415-3.542-3.415-2.023 0-3.541 1.518-3.415 3.415v1.77c-.759 0-1.39.507-1.39 1.266v4.68c0 .759.631 1.391 1.39 1.391h7.084c.758 0 1.39-.632 1.39-1.391v-4.68c-.252-.633-.884-1.139-1.517-1.139zm-2.783 4.807c0 .38-.379.759-.885.759-.38 0-.885-.253-.885-.76v-2.15c0-.379.253-.758.885-.758.506 0 .885.38.885.759v2.15zm.633-6.83v2.023h-3.036v-2.023c0-.76.76-1.392 1.518-1.392.885 0 1.644.506 1.518 1.392z"/></g>'
  }
})
