/**
 * Gestiona como almacenamos y recuperamos el idioma seleccionado
 */
const LanguageService = {
  getLanguage() {
    return localStorage.getItem('language');
  },

  saveLanguage(lang) {
    localStorage.setItem('language', lang);
  },

  removeLanguage() {
    localStorage.removeItem('language');
  },
};

/**
 * Gestiona como almacenamos y recuperamos el token y el refreshToken
 */
const TokenService = {
  getToken() {
    return localStorage.getItem('access_token');
  },

  saveToken(accessToken) {
    localStorage.setItem('access_token', accessToken);
  },

  removeToken() {
    localStorage.removeItem('access_token');
  },

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem('refresh_token', refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem('refresh_token');
  },
};

/**
 * Gestiona como almacenamos y recuperamos el idioma seleccionado
 */
const EnviromentService = {
  getEnviroment() {
    return localStorage.getItem('appMode');
  },

  saveEnviroment(mode) {
    localStorage.setItem('appMode', mode);
  },

  removeEnviroment() {
    localStorage.removeItem('appMode');
  },
};

export { LanguageService, TokenService, EnviromentService };
