import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LanguageService } from '@/services/storage.service';

Vue.use(VueI18n);

/**
 * Cargamos los idiomas
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};

  // prettier-ignore
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i);

    if (matched && matched.length > 1) {
      const split = matched.input.split('/');
      const locale = split[1];
      const section = matched[1];

      if (!messages[locale]) {
        messages[locale] = {};
      }

      messages[locale][section] = locales(key);
    }
  });
  const localStorageLanguage = LanguageService.getLanguage();
  const navigatorLanguage = navigator.language.split('-');
  const locale = localStorageLanguage || navigatorLanguage[0] || 'en';
  const fallbackLocale = 'en';

  return new VueI18n({ locale, fallbackLocale, messages });
}

const i18n = loadLocaleMessages();

/**
 * Cambia el idioma de la aplicación
 *
 * @param {String} lang - Código del idioma seleccionado
 * @return {String} - Código del idioma seleccionado
 * @throws {AppError} - langNotFound
 */
const setLanguage = async (lang = i18n.locale) => {
  document.querySelector('html').setAttribute('lang', lang);
  //
  // Si el idioma es el mismo que está seleccionado, no hago nada
  //
  if (i18n.locale === lang) return lang;

  //
  // Actualizo el idioma
  //
  i18n.locale = lang;
  LanguageService.saveLanguage(lang);

  return lang;
};

export { setLanguage };

export default i18n;
