import axios from 'axios';
import i18n from '@/i18n';
// import ApiService from '@/services/api.service';
import CONSTANTS from '@/constant';

/**
 * Devuelve un objecto con los datos del contenido, que se rendiran en la vista Support Docs
 * TODO => Se debe cambiar este método a una util
 * @param { String } id - Id del contenido
 * @param { Object } data - Datos del contenido
 * @returns { Object } Datos mapeados del contenido
 */
const mapContent = (id, data) => {
  return {
    categoryID: id,
    category: data.name,
    title: data.name,
    text: data.description, // "<h1>Conexión</h1>\n<h2>Aidoo control Wi-Fi Daikin Residential</h2>\n<div class=\"caption\">\nEl mando de la unidad Daikin debe mantenerse funcionando paralelamente a su Aidoo.</div>\n<p><strong>Realice todas las conexiones con ausencia total de alimentación.</strong></p>\n<p>Siga los pasos descritos a continuación para realizar su conexión:</p>\n<ol>\n<li>\n<p>Fije su Aidoo cerca de la unidad que desee controlar mediante el material suministrado (adhesivo de doble cara o tornillos).</p>\n<p><strong>Importante:</strong> Compruebe que dispone de señal Wi-Fi antes de realizar el montaje.<br />\n<img src=\"https://doc.airzonecloud.com/aidoo_support/images/assembly_Aidoo.png\" alt=\"Assembly Aidoo \" /></p>\n</li>\n<li>Retire la alimentación de la unidad interior.</li>\n<li>Localice el conector <strong>S21</strong> en la electrónica de la unidad interior Daikin Residential y conecte a este puerto su Aidoo mediante el cable sumunistrado.<br />\n<img src=\"https://doc.airzonecloud.com/aidoo_support/images/connection_DA0.png\" alt=\"Connection Aidoo DA0\" /></li>\n<li>Alimente la unidad interior. Compruebe los LED:<br />\n<span class=\"ledIcon\"><i class=\"icon-operation\"></i></span> verde (parpadeo)<br />\n<span class=\"ledIcon\"><i class=\"icon-power\"></i></span> rojo (fijo)</li>\n</ol>",
    video: getEmbedURL(data.video_url), // "https://myzone.airzone.es/api/videosapp/aidoo/es/aidooins_da0",
  }
}

/**
 * Convierte una URL de vídeo como embebida si es de youtube
 * TODO => Se debe cambiar este método a una util
 * @param { String } url - URL del vídeo a convertir como embebida si es de youtube
 * @returns { Object } Datos de las categorías
 */
const getEmbedURL = (url) => {

  if (!url || url?.includes('https://www.youtube.com/embed') || url?.includes('https://youtu.be/embed')) return url

  if (url.includes('https://youtu.be')) {
    return url.replace('youtu.be/', 'www.youtube.com/embed/')
  } else if (url.includes('https://www.youtube.com')) {
    return url.replace('watch?v=', 'embed/')
  }
  else {
    return url
  }

}

/**
 * Obtiene las secciones de una categoría
 * Nota: Se debe hacer este método como público si es necesario
 * @param { String } categoryID - ID de la categoría para obtener sus secciones
 * @returns { Object } Datos de las secciones de la categoría
 */
const getSupportSection = async (categoryID) => {

  const request = axios.create({
    baseURL: `${CONSTANTS.CONNECT.APP.AZSUPPORT_API_KONG_GATEWAY}${CONSTANTS.CONNECT.APP.AZSUPPORT_API_MSMULTIMEDIA}.pv1`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'app-locale': i18n.locale,
      'app-market': 'ib', // 'pcom'
      'apikey': CONSTANTS.CONNECT.APP.AZSUPPORT_API_KEY,
    },
  });

  // Añado preview=1 si está en el entorno de desarrollo
  let preview = ''

  if (CONSTANTS.APP_ENV === 'dev') {
    preview = 'preview=1'
  }
  
  const sectionData = await request.get(`/digital-doc/sections/${categoryID}?${preview}`); 

  return sectionData?.data?.body?.digital_section.digital_subsections
}

/**
 * Asigna los datos de categorías devueltos por el backend a la interfaz de la vista Support View
 * TODO => Se debe cambiar este método a una distinta al servicio
 * @param { Array } support - Array de categorías
 * @returns { Object } Datos de las categorías
 */
const cloud2webCategories = (support) => {
  const faqsTree = {};

    // Recorro las categorías para asignar los datos devueltos por el backend a la interfaz de la vista
    for(let i=0; i < support.length; i++) {
      const category = support[i].az_iso;
      // Compruebo si ya he asignado la categoría
      if (!Object.prototype.hasOwnProperty.call(faqsTree, category)) {
        faqsTree[category] = {
          title: support[i].name,
          icon: support[i].image_url,
          // subthread: [],
        };
      }
    }
    // console.log('faqsTree: ', faqsTree)
    return { faqsTree }
}

const SupportService = {

  async getSupportData() {

    const instance = axios.create({
      baseURL: `${CONSTANTS.CONNECT.APP.AZSUPPORT_API_KONG_GATEWAY}${CONSTANTS.CONNECT.APP.AZSUPPORT_API_MSMULTIMEDIA}.pv1`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'app-locale': i18n.locale,
        'app-market': 'ib', // 'pcom'
        'apikey': CONSTANTS.CONNECT.APP.AZSUPPORT_API_KEY,
      },
    });

    const supportData = await instance.get(`/digital-doc/books?`);
    // Filtramos por el primer book digital para obtener las categorías
    const sections = supportData?.data?.body?.digital_books?.data
    // console.log("Sections: ", sections)
    let book = {
      digital_sections: []
    }
    const finded_book = sections.find(section => section.az_iso === CONSTANTS.AZCLOUD_BOOK);
    if (finded_book) book = finded_book
    // console.log("Book: ", book)
    return cloud2webCategories(book.digital_sections)
  },

  /**
   * Proceso los datos
   */
  async getSupportThemes(categoryId) {
    // const faqs = {};
    const subthread = [];
    // console.log('support: ', support)

    // console.log('get: ', categoryId)
    const subsections = await getSupportSection(categoryId)
    // TODO => Crear un método para aislar el mapeo de datos
    if (subsections?.length > 0) {
      // console.log('subsections: ',  subsections)
      subsections.forEach( subsection => {
        // console.log(subsection)
        if (subsection?.digital_contents.length > 0) {
          subsection.digital_contents.forEach(content => {
            const faqs = mapContent(categoryId, content)
            // console.log('faqs: ', faqs)
            // const category = categoryId;
            const subcategory = subsection.name // subsection.az_iso// support.name
            // console.log('subcategory: ', subcategory)
            // if (!Object.prototype.hasOwnProperty.call(faqsTree, category)) {
            //   faqsTree[category] = {
            //     title: support.title,
            //     icon: support.icon,
            //     subthread: [],
            //   };
            // }

            if (subcategory) {
              let index = subthread.findIndex(item => item.title === subcategory);
              // console.log(support, index)
              if (index < 0) {
                subthread.push({
                  title: subsection.name,
                  subthread: [],
                });
              }
              // console.log(content.name)
              const elem = subthread.find(a => a.title === subsection.name)
              elem.subthread.push({
                category: content.name,
                title: content.name, // .replace(support.title, '- '),
                reference: content.az_iso,
                docs: faqs
              });
            } else {
              subthread.push({
                category: content.digital_content_iso,
                title: content.name,
                reference: content.az_iso,
                docs: faqs
              });
            }
          })
        }
      })
    }
    // console.log('return: ', subthread)
    return { subthread };
  },

  /**
   * Obtiene el contenido de la sección que pertenece a la categorías devueltos por el backend a la interfaz de la vista Support Docs
   * @param { String } contentId - Id del contenido
   * @returns { Object } Datos del contenido
   */
  async getSupportContent(contentId) {

    const request = axios.create({
      baseURL: `${CONSTANTS.CONNECT.APP.AZSUPPORT_API_KONG_GATEWAY}${CONSTANTS.CONNECT.APP.AZSUPPORT_API_MSMULTIMEDIA}.pv1`,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'app-locale': i18n.locale,
        'app-market': 'ib', // 'pcom'
        'apikey': CONSTANTS.CONNECT.APP.AZSUPPORT_API_KEY,
      },
    });
  
    // Añado preview=1 si está en el entorno de desarrollo
    let preview = ''

    if (CONSTANTS.APP_ENV === 'dev') {
      preview = 'preview=1'
    }

    const contentIdData = await request.get(`/digital-doc/content/${contentId}?${preview}`); 
    // console.log(contentIdData)
    return mapContent(contentId,contentIdData?.data?.body?.digital_content)
  }
};

export default SupportService;
