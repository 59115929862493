<template lang="pug">
.app
  Header(:logo="'img/airzone.svg'")
    .languages(slot="right")
      az-icon.margin-top-half(name="network", scale="0.7")
      az-select(
        v-model="lang"
        :showKey="true"
        @change="setLang")
        option(
          :value="key"
          v-for="(val, key) in availablesLanguages") {{ val }}
  
  router-view
</template>

<script>
import CONSTANTS from "@/constant";
import i18n, { setLanguage } from '@/i18n'
import Header from '@/components/Header.component.vue'
// import { reloadPage } from '@/utils/utils'

export default {
  components: { Header },

  data() {
    return {
      lang: i18n.locale,
      availablesLanguages: CONSTANTS.LANGUAGES,
      isLoading: false,
    };
  },

  methods: {
    setLang() {
      setLanguage(this.lang)
      window.location.reload(true);
      // reloadPage()
    },
  },

  mounted() {
    console.log(i18n.locale, this.lang)
    // Compruebo si el idioma está entre los disponibles, sino asigno inglés
    // eslint-disable-next-line no-prototype-builtins
    if (!CONSTANTS.LANGUAGES.hasOwnProperty(this.lang)) {
      setLanguage('en')
    }
    this.lang = i18n.locale
  }
}
</script>

<style lang="scss">
.languages {
  position: relative;
  display: flex;
  color: var(--color-content);
  svg {
    margin-right: .3rem
  }
}
</style>