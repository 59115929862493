<template lang="pug">
.header.main-header
  //- Left
  .header__left(role="button", @click="leftClick", @keyup.enter="leftClick")
    slot(name="left")
      .header__icon(
        v-if="leftIcon",
        :role="leftIcon === 'menu' ? 'menu' : ''",
        :tabindex="leftIcon === 'menu' ? 1 : 0",
        :aria-label="leftIcon === 'menu' ? 'menu' : leftIcon === 'left' ? $t('buttons.back') : ''"
      )
        az-icon(tabindex="-1", aria-hidden="true", :name="leftIcon", scale="1")
      .header__text(v-if="leftText") {{ leftText }}

  //- Title
  .header__title__box
    slot(name="title")
      .header__title(v-if="title", :class="{ marginTop: subtitle }") {{ title }}
      .header__subtitle(v-if="subtitle") {{ subtitle }}
      img.header__logo(
        aria-hidden="true",
        tabindex="-1",
        v-if="logo",
        :src="require(`@/assets/${logo}`)",
        alt="Airzone Cloud logo"
      )

  //- Right
  .header__right(role="button", @click="rightClick")
    slot(name="right")
      .header__text(v-if="rightText") {{ rightText }}
      .header__icon(v-if="rightIcon")
        az-icon(:name="rightIcon", scale="1")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    subtitle: {
      type: String,
      require: false,
    },

    logo: {
      type: String,
      default: "",
    },

    leftText: {
      type: String,
      defautl: "",
    },

    rightText: {
      type: String,
      default: "",
    },

    leftIcon: {
      type: String,
      defautl: "",
    },

    rightIcon: {
      type: String,
      default: "",
    },
  },

  methods: {
    leftClick() {
      this.$emit("click-left");
    },

    rightClick() {
      this.$emit("click-right");
    },
  },

  mounted() {
    // Ocultamos para voiceover cualquier imagen o icono genérica no controlada
    const icons = document.querySelectorAll("img");
    if (icons && icons.length > 0) {
      icons.forEach((icon) => {
        icon.setAttribute("aria-hidden", "true");
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$navBarHeight: 50px;

// .scrolled .main-header {
//   box-shadow: 0 1px 6px rgba(var(--color-shadow), 0.1),
//     0 4px 20px rgba(var(--color-shadow), 0.15);
// }

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100 !important;
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(#{$navBarHeight});
  padding-top: env(safe-area-inset-top);
  padding-top: var(--notch-inset-top);
  line-height: 1rem;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: color(background);
  box-shadow: none;
  transition: box-shadow 300ms ease-in-out;

  img {
    height: 24px;
    margin-left: calc(-1 * half());
  }
}

.header__left,
.header__right {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding-top: constant(safe-area-inset-top); // iOS 11
  padding-top: env(safe-area-inset-top); // iOS 11.2+
  padding-top: var(--notch-inset-top); // iOS 11.2+
  cursor: pointer;
  padding: 0 16px;
  min-width: 3rem;
  height: 50px;
  color: var(--color-brand);
  text-align: center;
}

.header__left {
  left: 0;

  .header__text {
    padding-left: half();
  }

  .header__icon:first-of-type {
    margin-left: quarter();
  }
}

.header__right {
  right: 0;
  line-height: double();

  .header__text {
    padding-right: half();
    color: color(brand);
  }

  .header__icon:last-of-type {
    margin-right: quarter();
  }

  // Agregamos estilo para un conmutador en esta zona
  .az-switch {
    margin-top: half();
    margin-right: half();
  }
}

.header__text {
  font-size: font-size(base);
  font-weight: font-weight(normal);
  cursor: pointer;
  transition: color 300ms;
}

.header__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: size(1.5);
  height: double();

  // &:focus {
  //   outline: 1px solid color(brand);
  // }
}

.header__title__box {
  padding: 1px 0 1px 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 60%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  font-size: font-size(base);
  font-weight: font-weight(bold);
  color: color(gray1);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header__title {
  line-height: 50px;
}

.header__title,
.header__subtitle {
  width: 100%;
}

.marginTop {
  margin-top: 0.5rem;
}

.header__subtitle {
  margin-top: 0.35rem;
  color: color(gray4);
  font-weight: font-weight(normal);
}
</style>
