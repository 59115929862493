const Scroll = {
  data() {
    return {
      scrolled: false,
    };
  },

  methods: {
    handleScroll(event) {
      this.scrolled = event.target.scrollTop > 0;
    },
  },
};

export default Scroll;
